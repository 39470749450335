<template>
  <div>
    <TheHeader />
    <transition mode="out-in">
      <slot />
    </transition>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheHeader from "@/components/TheHeader.vue";
import TheFooter from "@/components/TheFooter";
export default {
  name: "Dashboard",
  data() {
    return {
      novoUsuario: false
    };
  },
  components: {
    TheFooter,
    TheHeader
  },
  computed: {
    usuario: {
      get() {
        return this.$store.getters.getUsuario;
      },
      set(value) {
        this.$store.commit("UPDATE_USER", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>