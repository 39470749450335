<template>
  <section class="main">
    <div class="container pt-5 pb-5">
      <div class="row">
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column">
              <div class="d-flex flex-row justify-content-between w-100">
                <p class="mutted">Cursos Publicados</p>
                <p class="success d-none">+ 2 esse mês</p>
              </div>
              <h2 v-if="cursos.length > 0">
                {{ cursos.length }}
                <small v-if="cursos.length == 1">publicado</small>
                <small v-else>publicados</small>
              </h2>
              <h5 v-else>
                <span v-if="!loading">Nenhum curso publicado</span>
                <div class="spinner-grow" role="status" v-else>
                  <span class="sr-only">Loading...</span>
                </div>
              </h5>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'pdv'">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Ganhos do Mês</p>
                <p class="success d-none">+ 28.4%</p>
              </div>
              <h2 class="tertiary">{{ totalAreceber | currency }}</h2>
            </div>
            
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'uc'">
              <div class="d-flex flex-row justify-content-between w-100">
                <p>Total de alunos do mês</p>
                <p class="success d-none">+ 28.4%</p>
              </div>
              <h2 class="tertiary">{{ totalAlunosMes }}</h2>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'pdv'">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Total vendido</p> 
                  <p class="success d-none">+ 12.2%</p>
                </div>
                <h2>{{ totalVendidocompleto | currency }}</h2>
              <small>
                Total de alunos
                <b>{{ totalAlunos }}</b>
              </small>
              </div>

              <div class="card-body d-flex justify-content-between align-items-start flex-column" v-if="$wlType === 'uc'">
                <div class="d-flex flex-row justify-content-between w-100">
                  <p>Total de alunos</p> 
                  <p class="success d-none">+ 12.2%</p>
                </div>
                <h2>{{ totalAlunos }}</h2>
              </div>
          </div>
        </div>
        <!-- <div class="col-md-8 pt-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body">
              {{datacollection}}
              <line-chart :chart-data="datacollection"></line-chart>
              <button @click="fillData()">Randomize</button>
            </div>
          </div>
        </div>
        <div class="col-md-4 pt-md-4">
          <div class="card mt-3 mt-md-0 border-0 position-relative">
            <div class="card-body"></div>
          </div>
        </div> -->
      </div>
      <CursosProfessor :role="group" />
    </div>
  </section>
</template>
<script>
import CursosProfessor from "@/components/CursosListaProfessor.vue";
import LineChart from "../../components/LineChart";
export default {
  name: "Homeprofessor",
  components: {
    CursosProfessor,
    // eslint-disable-next-line vue/no-unused-components
    LineChart,
  },
  data() {
    return {
       datacollection: null,
      chart: "",
      value: "",
      loading: false,
      cursos: [],
      faturas: [],
      totalVendido: 0,
      totalVendidocompleto: 0,
      totalAlunos: 0,
      totalAlunosMes: 0,
      totalAreceber:0
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group;
    },
  },

  methods: {
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    async getAlunos() {
      this.$http
        .get("/api/gestor/relatorios")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalAlunos = responseData["totalAlunos"];
          this.totalAlunosMes =  responseData["totalAlunosMes"];
        })
        .catch(() => {});
    },
    async getFaturas() {
      this.$http
        .get("/api/gestor/faturasgestor")
        .then((response) => 
        {
          var responseData =  response.data;
          this.totalCertificados = response.data.totalCertificados;
          this.totalAlunos = responseData["alunos"];
          this.totalAreceber = responseData["mes"];//PENDERE A RECEBER MÊS ATUAL
          this.totalVendidocompleto = responseData["all"];//TOTAL RECEBIDO
        })
        .catch(() => {});
    },
    getCursoDestaque() {
      this.loading = true;
      this.$http.get("/api/gestor/get/cursos/").then((response) => {
        this.cursos = response.data.entries;
        this.loading = false;
      });
    },
  },
  filters: {
    currency(valor) {
      var formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      });
      return formatter.format(valor);
    },
  },
  mounted() {
      //  this.fillData()
    document.title = this.$titleSite;
    this.getCursoDestaque();
    
    if(this.$wlType === 'pdv'){
      this.getFaturas();
    }
    if(this.$wlType === 'uc'){
      this.getAlunos();
    }
  },
};
</script>
<style lang="scss" scoped>
.card {
  position: relative;
  min-height: 140px;
}
</style>