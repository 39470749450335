<template>
  <base-template>
<fragment>
<Homealuno v-if="group == 'aluno'" />
<Homeprofessor v-if="group == 'professor'" />
<Homeprofessor v-if="group == 'gestor'" />
</fragment> 
  </base-template>
</template>
<script>
import BaseTemplate from "@/views/Dashboard/Dashboard.vue";
import Homealuno from './HomeAluno'
import Homeprofessor from './HomeProfessor'
export default {
  name: "Home",
  components: {
    BaseTemplate,
    Homealuno,
    Homeprofessor,
  },
  data() {
    return {
      cursoDestaque: "",
    };
  },
  computed: {
    group() {
      return this.$store.state.user.group
    },

  },
};
</script>
<style lang="scss" scoped>
.card-home {
  position: relative;

  p {
    color: var(--dark-2);
  }
  .card-text {
    color: var(--dark-2);
  }
  min-height: 240px;
  .tag {
    background: var(--tertiary);
    border-radius: 10px;
  }
  .hat {
    position: absolute;
    right: -50px;
    top: -50px;
    width: 15rem;
  }
}
.featured {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover !important;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background: var(--primary);
    opacity: 0.4;
    border-radius: 10px;
  }
}
</style>