<template>
    <fragment v-if="OpenChatIcon">
        <input type="checkbox" id="check">
        <label class="chat-btn btn btn-white btn-animate label" for="check">
            <img :src="logo" class="img-fluid comment" width="60" alt="logo" @click="OpenChat($event)" >
            <i class="material-icons close" @click="CloseChat($event)" > close </i>
        </label>
        <div class="wrapper">
            <div class="header" >
                <div class="row">
                    <div class="col-md-3 col-3">
                        <img :src="logo" class="img-fluid logo-avatar" width="60" alt="logo">
                    </div>
                    <div class="col-md-7  col-7 m-auto" style="margin-left: 0px !important;">
                        <h3>{{title}}</h3>
                    </div>    
                </div>
            </div>
            <div class="text-center p-2">
                <ul class="messages" ref="messages" id="messages">
                    <div v-for="(message, index) in listmessages" :key="`msg-${index}`">
                        <li class="message text-right ml-5 mr-4 mt-1" v-if="message.model == 'questionario'">
                            <p class="msg tex link" v-html="message.text" v-on:click="OpcaoChat(message.key)"></p>
                        </li>
                        <li class="message text-left ml-4 mr-5 mt-1" v-else-if="message.model == 'resposta'">
                            <p class="msg tex resposta" v-html="message.text"></p>
                        </li>
                        <li class="message text-right ml-5 mr-4 mt-1" v-else-if="message.model == 'anonymous'">
                            <button class="msg tex mr-2 bt" v-on:click="Anonymous(true)">Sim</button>
                            <button class="msg tex bt" v-on:click="Anonymous(false)">Não</button>
                        </li>
                        <li class="message text-right ml-5 mr-4 mt-1" v-else>
                            <p class="msg tex" v-html="message.text"></p>
                        </li>
                    </div>
                </ul>
                <div class="loadingid message float-right mt-1">
                    <loading color="var(--primary)" v-if="load == true"/>
                </div>
            </div>
            <div class="chat-form">
                <div v-show="form == true">
                    <form action v-on:submit.prevent="PostMgs">
                        <textarea class="form-control" placeholder="Sua Mensagem de Texto" required
                            v-model="textarea"></textarea>
                            <input type="file" name="file" id="file" ref="file" multiple @change="onChangeFileUpload()"
                                accept="image/*, application/pdf" style=" display: none !important; "/>

                                <label class="iput_file text-center" for="file" v-show="formInput">
                                    <span class="material-icons"> upload_file </span>&nbsp;
                                    <span v-if="file.length == 0">Anexar Arquivos</span>
                                    <span v-else>Arquivos Anexados</span>
                                </label>

                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true" :disabled="textarea.length == 0 && file.length == 0">
                            <span>Enviar</span>
                            <!--
                            <span v-if="textarea.length > 0">Enviar</span>
                            <span v-else>Pular</span>
                            -->
                        </button>
                    </form>
                </div>
                <div v-if="name == true">
                    <form action v-on:submit.prevent="Check('name', false)">
                        <input type="text" class="form-control" placeholder="Digite seu nome completo" v-model="textarea" required/>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true" :disabled="textarea.length == 0">
                            <span>Enviar</span>
                            <!--
                            <span v-if="textarea.length > 0">Enviar</span>
                            <span v-else>Pular</span>
                            -->
                        </button>
                    </form>
                </div>
                <div v-if="cpf == true">
                    <form action v-on:submit.prevent="Check('cpf', false)">
                        <input type="text" class="form-control" placeholder="Digite seu CPF" autocomplete="off"
                            v-mask="['###.###.###-##']" v-model="textarea" style=" height: 62px; " required/>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true" :disabled="textarea.length == 0">
                            <span>Enviar</span>
                            <!--
                            <span v-if="textarea.length > 0">Enviar</span>
                            <span v-else>Pular</span>
                            -->
                        </button>
                    </form>
                </div>
                <div v-if="telefone == true">
                    <form action v-on:submit.prevent="Check('telefone', false)">
                        <input type="text" class="form-control" placeholder="Digite seu telefone" autocomplete="off"
                            v-mask="['(##)#####-####']" v-model="textarea" style=" height: 62px; " required/>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true" :disabled="textarea.length == 0">
                            <span>Enviar</span>
                            <!--
                            <span v-if="textarea.length > 0">Enviar</span>
                            <span v-else>Pular</span>
                            -->
                        </button>
                    </form>
                </div>
                <div v-if="email == true">
                    <form action v-on:submit.prevent="Check('email', false)">
                        <input type="email" class="form-control" placeholder="Digite seu nome e-mail" v-model="textarea" required/>
                        <button class="btn bg-primary btn-block" type="submit" role="status" aria-hidden="true" :disabled="textarea.length == 0">
                            <span>Enviar</span>
                            <!--
                            <span v-if="textarea.length > 0">Enviar</span>
                            <span v-else>Pular</span>
                            -->
                        </button>
                    </form>
                </div>
                <div v-if="checkDate == true">
                    <form action v-on:submit.prevent="PostDate" class="input-group">
                        <button class="btn bg-primary btn-block" type="submit" role="status"
                            aria-hidden="true">Confirma</button>
                    </form>
                </div>
            </div>
        </div>
    </fragment>
</template>
<script>
import { cpf } from "cpf-cnpj-validator";
import { cnpj } from "cpf-cnpj-validator";

export default {
    name: "Chat",
    props: {
    },
    components: {
        cpf,
        cnpj
    },
    data() {
        return {
            title:'',
            logo:'',
            atributo:'',
            anonymous:null,
            wlToken: null,
            observer: null,
            group: 'usuario',
            color: '--var(primary)',
            OpenChatIcon: false,
            pendente: true,
            valido: false,
            load: '',
            listmessages: [],
            checkout: false,
            messagefim: false,
            close: false,
            dateMin: "",
            Template: [],
            scrollTop: 0,
            i: 0,
            e: 1,
            time: 1,
            textarea: '',
            file: [],
            formData: {
                user_id: "",
                name: "",
                cpf: "",
                telefone: "",
                email: "",

                group: "",
                platform: this.$titleSite,
                key: null,
                option: "",
                text: "",
                timestamp: {
                    day: "",
                    hour: ""
                },
                files: [],
            },

            arrayInput: [],

            conclusao: false,
            form: true,
            formInput: true,
            name: false,
            cpf: false,
            telefone: false,
            email: false,
            checkDate: false,
            inicio: 0,
        };
    },
      methods: {
        Anonymous(status){
            this.anonymous = status;
            this.PosformChat();
        },
        ValidDocument(document) {
            if (document.replace(/[^0-9]/g, '').length == 11) {
                return cpf.isValid(document);
            } else {
                return cnpj.isValid(document);
            }
        },
        scrollTo(value) {
            setTimeout(() => {
                this.$refs.messages.scrollTop = value;
            }, 100);
        },
        msgload(boolean) {
            this.load = boolean;
        },
        OpenChat() {
            try {
                this.messagefim = false;
                if (this.close == true) {
                    this.listmessages = [];
                    this.close = false;
                }
                setTimeout(() => {
                    this.msgload(true);
                }, 1000);

                //this.Template['abordagem']
                //this.Template['questionario']
                var model = this.Template['abordagem'].concat(this.Template['questionario'])
                setTimeout(() => {
                    //IMPRIMIR TODAS AS MENSAGENS => abordagem + questionario
                    if (this.i < model.length) {
                        this.msgload(false);
                        this.listmessages.push(model[this.i]);
                        this.scrollTop += 100;
                        this.time = 1;
                        this.scrollTo(this.scrollTop);
                        this.i++;
                        this.msgload(true);
                        this.OpenChat();
                    } else {
                        this.msgload(false);
                        this.checkout = true;
                        this.i = 0;
                    }
                }, (this.time * 1000));
            } catch (error) {
	            console.log('ERRO => '+error)
            }
        },
        Opcoes(e = 0) {
            try {
                this.msgload(true);
                setTimeout(() => {
                    if (e < this.Template['questionario'].length) {
                        this.msgload(false);
                        this.listmessages.push(this.Template['questionario'][e]);
                        this.scrollTop += 100;
                        //this.time = this.Template[this.e]["messages"]["time"];
                        this.scrollTo(this.scrollTop);
                        e++;
                        this.Opcoes(e);
                    } else {
                        this.msgload(false);
                        this.e = 0;
                        e = 0;
                    }
                }, (this.time * 1000));
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        OpcaoChat(opc) {
            try {
                this.messagefim = false;
                this.arrayInput = [];
                this.msgload(true);
                if (this.checkout == false) {
                    this.msgload(false);
                    /*
                    if(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != ''){
                        this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);
                    }*/
                } else {
                    /*
                    this.msgload(false);
                    if(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != ''){
                        this.listmessages.push(JSON.parse('{"id": '+this.scrollTop+', "text": "'+this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>")+'","model":"resposta"}'));
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);
                    }*/
                    this.msgload(true);
                    setTimeout(() => {
                        /*
                        for(var m = 0; m < this.Template.length; m++){
                            if(this.Template[m]["messages"]["id"] == opc){
                                this.msgload(false);
                                this.listmessages.push(this.Template[m]["messages"]["opcao"]);
                                this.formData.option = this.Template[m]["messages"]["text"];
                                this.messagefim = true;
                                this.scrollTop += 100;
                                this.scrollTo(this.scrollTop);
                            }
                        }
                        */
                        this.msgload(false);
                        this.Template['questionario']

                        var posicao = this.Template['questionario'].findIndex(objeto => objeto.key === opc);

                        this.listmessages.push(this.Template['questionario'][posicao]["opcao"]);
                        this.formData.option = this.Template['questionario'][posicao]["text"];
                        this.messagefim = true;
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);
                    }, 1000);
                }
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        Erro() {
            try {
                for (var t = 0; t < this.Template["informacao"].length; t++) {
                    if (this.Template["informacao"][t]["opc"] == "erro") {
                        return this.Template["informacao"][t];
                    }
                }
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        opc(opc) {
            try {
                var idopc = [];
                for (var t = 0; t < this.Template['questionario'].length; t++) {
                    // if(["model"] == "questionario"){
                    idopc.push(
                        {
                            'array': this.Template['questionario'][t]["key"],
                            'item': this.Template['questionario'][t]["cont"],
                        }
                    );
                    // }
                }
                var retu = {
                    'status': false
                };
                for (var op = 0; op < idopc.length; op++) {
                    if (opc == idopc[op]['item']) {

                        retu = {
                            'array': idopc[op]['array'],
                            'status': true
                        };
                        return retu;
                    } else {
                        retu = {
                            'status': false
                        };
                    }
                }
                return retu;
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        onChangeFileUpload() {
            this.file = this.$refs.file.files;
        },
        PostMgs() {
            try {
                if (this.messagefim == true) {
                    //console.log(this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>"));
                    //MENSAGEM FINAL PARA PROCESSAMENTO
                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                    this.scrollTop += 100;
                    this.scrollTo(this.scrollTop);
                    this.formData.text = this.textarea;
                    if (this.file.length > 0) {
                        for (let file of this.file) {
                            this.listmessages.push(JSON.parse(`{"id": ` + this.scrollTop + `, "text":"<i class='material-icons' style='font-size: 30pt;'> upload_file </i>` + file['name'].replace(/(\r\n|\n|\r)/gm, "<br>") + `","model":"resposta"}`));
                        }
                    }
                    this.formInput = false;
                    this.PosformChat();
                } else {
                    //if(this.anonymous){
                        if (this.checkout == false) {
                            if (this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") != '') {
                                this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                                this.scrollTop += 100;
                                this.scrollTo(this.scrollTop);
                                this.textarea = '';
                            }
                        } else {
                            if (this.textarea != '') {
                                this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                                this.textarea = this.textarea.replace(/([^\d])+/gim, '');
                                this.scrollTop += 100;
                                this.scrollTo(this.scrollTop);
                                var opc = this.textarea;
                                this.textarea = '';

                                var valid = this.opc(opc);
                                if (valid['status'] == true) {
                                    this.OpcaoChat(valid['array']);
                                } else {
                                    setTimeout(() => {
                                        this.listmessages = [];
                                        this.listmessages.push(this.Erro());
                                        this.msgload(true);
                                        this.scrollTop = 0;
                                        this.scrollTo(this.scrollTop);
                                        this.Opcoes();
                                    }, 2000);
                                }
                            }
                        }
                    /*}else{
                        this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);
                        this.formData.text = this.textarea;
                        if (this.file.length > 0) {
                            for (let file of this.file) {
                                this.listmessages.push(JSON.parse(`{"id": ` + this.scrollTop + `, "text":"<i class='material-icons' style='font-size: 30pt;'> upload_file </i>` + file['name'].replace(/(\r\n|\n|\r)/gm, "<br>") + `","model":"resposta"}`));
                            }
                        }
                        this.formInput = false;
                        this.PosformChat();
                    }*/
                }

            } catch (error) {
                console.log('ERRO => ' + error)
            }
        },
        final(tipo, final) {
            try {
                for (var t = 0; t < this.Template['informacao'].length; t++) {
                    if (this.Template['informacao'][t]["cont"] == final) {
                        return this.Template['informacao'][t];
                    }
                }
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        PosformChat() {
            try {
                //this.formData.user = this.userData.email;
                this.textarea = '';
                this.msgload(true);
                setTimeout(() => {
                    this.msgload(false);
                    if (this.Template.anonymous && this.anonymous == null) {
                        this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "'+this.Template.anonymoustext+'","model":"abordagem"}'));
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);

                        this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "","model":"anonymous"}'));
                        this.scrollTop += 100;
                        this.scrollTo(this.scrollTop);

                    }

                    if(this.anonymous ==  false && this.anonymous != null){

                        if (this.Template['informacao'][1]['status'] == true) {
                            this.listmessages.push(this.final('informacao', 1));
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            this.Template['informacao'][1]['status'] = false;
                        }
                        
                        if (typeof this.formData.name == "undefined" || this.formData.name == "" && this.Template['informacao'][2]['status'] == true && this.arrayInput.includes('name') == false) {
                            this.listmessages.push(this.final('informacao', 2));
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            this.Check('name', true);
                            this.name = true;
                        } else if (typeof this.formData.cpf == "undefined" || this.formData.cpf == "" && this.Template['informacao'][3]['status'] == true && this.arrayInput.includes('cpf') == false) {
                            this.listmessages.push(this.final('informacao', 3));
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            this.Check('cpf', true);
                            this.cpf = true;
                        } else if (typeof this.formData.telefone == "undefined" || this.formData.telefone == "" && this.Template['informacao'][4]['status'] == true && this.arrayInput.includes('telefone') == false) {
                            this.listmessages.push(this.final('informacao', 4));
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            this.Check('telefone', true);
                            this.telefone = true;
                        } else if (typeof this.formData.email == "undefined" || this.formData.email == "" && this.Template['informacao'][5]['status'] == true && this.arrayInput.includes('email') == false) {
                            this.listmessages.push(this.final('informacao', 5));
                            this.scrollTop += 100;
                            this.scrollTo(this.scrollTop);
                            this.Check('email', true);
                            this.email = true;
                        } else {
                            setTimeout(() => {
                                for (var t = 0; t < this.Template['finalizar'].length; t++) {
                                    //if(this.Template[t]["model"] == 'finalizar' && this.Template[t]["cont"] == 1){
                                    this.listmessages.push(this.Template['finalizar'][t]);
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    //}
                                }
                            }, 1000);

                            this.CloseChat();
                            let formData = new FormData();
                            if (this.file.length > 0) {
                                for (var i = 0; i < this.file.length; i++) {
                                    let file = this.file[i];
                                    formData.append('file[' + i + ']', file);
                                }
                            }
                            this.textarea = '';
                            this.formData.key = this.wlToken;
                            if(this.atributo.length > 0){
                                this.formData.atributo	 = this.atributo;
                            }

                            formData.append('data', JSON.stringify(this.formData));

                            this.$http.
                                post(this.$SiteChat+'admin/api/user/createChat', formData,
                                    {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                .then((response) => {
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('Seu número de protocolo e: <b>' + response.data.protocol+'</b>').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('E você pode acompanhar o status do seu chamado através deste link: <a href='+ this.$SiteChat + 'chamado/' + response.data.protocol + ' target=_blank>'+ this.$SiteChat + 'chamado/' + response.data.protocol + '</a>').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('Mantenha este número de protocolo em mãos, pois ele será necessário para verificar o andamento do seu chamado. Qualquer atualização relevante será exibida na página de acompanhamento do seu chamado.').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.loading = false;
                                    this.$toast.open({
                                        message:
                                            "Mensagem foi enviada!",
                                        type: "success",
                                        duration: 3600,
                                    });
                                })
                                .catch((error) => {
                                    this.loading = false;
                                    this.$toast.open({
                                        message: error.response.data.error,
                                        type: "error",
                                    });
                                });

                        }
                    }
                    if(this.anonymous){
                        setTimeout(() => {
                                for (var t = 0; t < this.Template['finalizar'].length; t++) {
                                    //if(this.Template[t]["model"] == 'finalizar' && this.Template[t]["cont"] == 1){
                                    this.listmessages.push(this.Template['finalizar'][t]);
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    //}
                                }
                            }, 1000);

                            this.CloseChat();
                            let formData = new FormData();
                            if (this.file.length > 0) {
                                for (var i = 0; i < this.file.length; i++) {
                                    let file = this.file[i];
                                    formData.append('file[' + i + ']', file);
                                }
                            }
                            this.textarea = '';
                            this.formData.key = this.wlToken;
                            if(this.atributo.length > 0){
                                this.formData.atributo	 = this.atributo;
                            }
                            formData.append('data', JSON.stringify(this.formData));

                            this.$http.
                                post(this.$SiteChat+'admin/api/user/createChat', formData,
                                    {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                .then((response) => {
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('Seu número de protocolo e: <b>' + response.data.protocol+'</b>').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('E você pode acompanhar o status do seu chamado através deste link: <a href='+ this.$SiteChat + 'chamado/' + response.data.protocol + ' target=_blank>'+ this.$SiteChat + 'chamado/' + response.data.protocol + '</a>').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + ('Mantenha este número de protocolo em mãos, pois ele será necessário para verificar o andamento do seu chamado. Qualquer atualização relevante será exibida na página de acompanhamento do seu chamado.').replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"abordagem"}'));
                                    this.scrollTop += 100;
                                    this.scrollTo(this.scrollTop);
                                    this.loading = false;
                                    this.$toast.open({
                                        message:
                                            "Mensagem foi enviada!",
                                        type: "success",
                                        duration: 3600,
                                    });
                                })
                                .catch((error) => {
                                    this.loading = false;
                                    this.$toast.open({
                                        message: error.response.data.error,
                                        type: "error",
                                    });
                                });
                    }
                }, 2000);
            } catch (error) {
                console.log(error)
            }
        },
        PostDate() {
            try {
                //this.CheckDate(false);
                this.PosformChat();
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        /*
        CheckDate(value){
            this.checkDate = value;
          if(value == true){
              this.form = false;
              setTimeout(() => {
                  var date = new Date();
                  this.dateMin = date.getFullYear()+'-'+("00" + (date.getMonth()+1)).slice(-2)+'-'+("00" + date.getDate()).slice(-2);
                  this.$refs.day.setAttribute('min',this.dateMin);
                      //this.$refs.messages.scrollTop = value;
              }, 100);
          }else{
              this.form = true;
          }
        },*/
        Check(value, check) {
            try {
                switch (value) {
                    case 'name':
                        //this.name = check;
                        this.name = false;
                        if (check == true) {
                            this.form = false;
                        } else {
                            this.form = true;
                            this.formData.name = this.textarea;
                            if (this.textarea.length > 0) {
                                this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                                this.arrayInput.push('name');
                            } else {
                                this.arrayInput.push('name');
                            }
                            this.textarea = '';
                            this.PosformChat();
                        }
                        break;

                    case 'cpf':
                        if (check == false) {
                            if (this.textarea.length > 0) {
                                this.valido = this.ValidDocument(this.textarea);
                                this.pendente = false;
                                if (this.valido == false) {
                                    this.$toast.open({
                                        message: "CPF Inválido.",
                                        type: "error",
                                    });
                                    this.pendente = false;
                                }

                                if (this.valido == true) {
                                    this.cpf = check;
                                    if (check == true) {
                                        this.form = false;
                                    } else {
                                        this.form = true;
                                        this.formData.cpf = this.textarea;
                                        this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                                        this.textarea = '';
                                        this.PosformChat();
                                    }
                                }
                            } else {
                                this.cpf = false;
                                this.form = true;
                                this.formData.cpf = this.textarea;
                                this.textarea = '';
                                this.arrayInput.push('cpf');
                                this.PosformChat();
                            }

                        } else {
                            this.cpf = check;
                            this.form = false;
                        }
                        break;

                    case 'telefone':
                        //this.telefone = check;
                        this.telefone = false;
                        if (check == true) {
                            this.form = false;
                        } else {
                            this.form = true;
                            this.formData.telefone = this.textarea;
                            if (this.textarea.length > 0) {
                                this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                            }
                            this.textarea = '';
                            this.arrayInput.push('telefone');
                            this.PosformChat();
                        }
                        break;

                    case 'email':
                        //this.email = check;
                        this.email = false;
                        if (check == true) {
                            this.form = false;
                        } else {
                            this.form = true;
                            this.formData.email = this.textarea;
                            if (this.textarea.length > 0) {
                                this.listmessages.push(JSON.parse('{"id": ' + this.scrollTop + ', "text": "' + this.textarea.replace(/(\r\n|\n|\r)/gm, "<br>") + '","model":"resposta"}'));
                            }
                            this.textarea = '';
                            this.arrayInput.push('email');
                            this.PosformChat();
                        }
                        break;
                }

                this.scrollTop += 100;
                this.scrollTo(this.scrollTop);
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
        CloseChat() {
            this.close = true;
        },
        MensageBot() {
            try {
                this.Template = [];
                this.$http
                    .post(this.$SiteChat+`admin/api/user/chat`,
                        {
                            wlToken: this.wlToken
                        })
                    .then(response => {
                        this.Template = response.data;
                        this.logo = this.$SiteChat+this.Template.layout.icone;
                        this.title = this.Template.layout.title;
                        //this.Template.anonymous = false;
                        if(!this.Template.anonymous){
                            this.anonymous = false;
                        }
                        //this.Template.anonymoustext = 'Deseja fazer denúncia anônima?';
                        document.documentElement.style.setProperty('--primary', this.Template.layout.color);
                        var td = 0;
                        /*ANTIGO
                        for(var t=0; t < this.Template.length; t++){
                            if(this.Template[t]["model"] == "abordagem" || this.Template[t]["model"] == "questionario"){
                                td += 1;
                            }
                        }
                        */
                        this.inicio = this.Template['abordagem'].length + this.Template['questionario'].length;
                        this.OpenChatIcon = true;
                        /*
                        setTimeout(() => {
                            this.observer = new IntersectionObserver(entries => {
                                // We only have one entry. Is it visible?
                                if (entries[0].intersectionRatio > 0) {
                                    this.OpenChat();
                                }
                            });
                            // Observe the dummy footer element
                            this.observer.observe(this.$refs.wrapper);
                        }, 3000);
                        */
                    })
                    .catch(() => { });
            } catch (error) {
                console.log('ERRO => '+error)
            }
        },
    },
    mounted() {
        if (this.$route.query.key != undefined && this.$route.query.key.length > 0) {
            this.wlToken = this.$route.query.key;
        }
        this.wlToken = this.$TokenChat;
        //alterando favicon
        document.querySelector("[rel='icon']").href = this.$apiURL + 'images/eugenio.png';
        //alterando title
        document.querySelector("title").innerText = this.$titleSite;
        /*
        if(this.$route.query.color != undefined && this.$route.query.color.length == 6){
            this.color = '#' + this.$route.query.color;
        }
        //alterando cor variavel --primary via GET somente cor Hexadecimal
        document.documentElement.style.setProperty('--primary', this.color);
        */
        //document.documentElement.style.setProperty('--primary', '#07397d');
        this.group = 'visitante';
        this.formData.group = 'visitante';
        
        //Identificação da Plataforma em que esta logado
        if (this.$route.query.title != undefined || this.$route.query.title != '') {
            this.formData.platform = this.$route.query.title;
        }
        if (this.$route.query.slug != undefined || this.$route.query.slug != null) {
            this.atributo = this.$route.query.slug;
        }
        if(typeof this.$store.state.user.name != "undefined" || this.$store.state.user.name != ""){
            this.formData.name = this.$store.state.user.name;
        }
        if(typeof this.$store.state.user.group != "undefined" || this.$store.state.user.group != ""){
            this.formData.group = this.$store.state.user.group; 
        }else{
            this.formData.group = "visitante";
        }
        if(typeof this.$store.state.user.cpf != "undefined" || this.$store.state.user.cpf != ""){
            this.formData.cpf = this.$store.state.user.cpf;
        }
        if(typeof this.$store.state.user.telefone != "undefined" || this.$store.state.user.telefone != ""){
            this.formData.telefone = this.$store.state.user.telefone;
        }
        if(typeof this.$store.state.user.email != "undefined" || this.$store.state.user.email != ""){
            this.formData.email = this.$store.state.user.email;
        }
        if(typeof this.$store.state.user._id != "undefined" || this.$store.state.user._id != ""){
            this.formData.user_id = this.$store.state.user._id;
        }
        this.MensageBot();
/*
        setTimeout(() => {
            this.observer = new IntersectionObserver(entries => {
                // We only have one entry. Is it visible?
                if (entries[0].intersectionRatio > 0) {
                    this.OpenChat();
                }
            });
            // Observe the dummy footer element
            this.observer.observe(this.$refs.wrapper);
        }, 3000);
        */
        //console.log(this.$route.query.open);
    },
    destroyed() {
        this.observer.disconnect();
    },
};
</script>


<style scoped>

.iput_file{
    color: #5a5a5a;
    border: 2px solid #ccc;
    border-radius: 5px;
    width: -webkit-fill-available;
    padding: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    border-style: dashed;
    display: flex;
    justify-content: center;
    align-items: center;
}

body {
    background: none !important;
    background-color: none !important;
}

i>.material-icons {
    font-size: 30pt !important;
}

.loading {
    margin-top: 0px !important;
}
.loadingid{
    margin-top: -40px !important;
    margin-right: 20px;
}
.img-fluid{
    width: 40px;
    height: 40px;
}
.lod{
    right: 30px;
    bottom: 170px;
    position: absolute;
}
.label {
  background: #4FD1C5;
  background: linear-gradient(90deg, rgb(0 0 0 / 0%) 0%, rgb(0 0 0 / 0%) 100%);
  border: none;
  border-radius: 50%;
  box-shadow: 0px 0px 20px var(--primary);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  }


.label:hover, .label:focus {
  color: #313133;
  /*transform: translateY(-6px);*/
}

label:hover::before, label:focus::before {
  opacity: 1;
}

label::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid var(--primary);
  position: absolute;
  z-index: -1;
  transform: translate(-50%, -50%);
  /*animation: ring 1.5s infinite;*/
  animation: ring 1.5s; 
  animation-iteration-count: 5;
  top: 40px;
  left: 40px;
}

label:hover::after, label:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}

/*####################*/
.btn:link,
.btn:visited {
    text-transform: uppercase;
    text-decoration: none;
    display: inline-block;
    transition: all .2s;
    position: absolute;
    background: var(--primary) !important;
}

.btn:hover {
    /*transform: translateY(-3px);*/
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btn:active {
    /*transform: translateY(-1px);*/
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.btn-white {
    background-color: white;
    color: #777;
}

.btn::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    border-radius: 100px;
    position: absolute;
    z-index: -1;
    transition: all .4s;
    
}

.btn-white::after {
    background-color: var(--primary) !important;
}

.btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0;
}

.messages{
    height: 300px;
    overflow-y: scroll;
    border: solid 1px;
    padding: 0px 0px 45px 0px;
}
p:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	right: -10px;
	top: 10px;
	color: var(--primary) !important;
    background-color: white !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: white;
    border-bottom-color: white;
}
.resposta{
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    display: inline-block;
    padding: 10px 10px 10px 15px !important;
    border-radius: 6px;
    /*width: calc(100% - 15px);*/
    width:auto;
    /*min-width: 100px;*/
    position: relative;
    color: white !important;
    background-color: var(--primary) !important;
    border: 1px solid;
}
.resposta:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	left: -10px;
	top: 10px;
	color: white !important;
    background-color: var(--primary) !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    border-bottom-color: var(--primary);
}
.logo-avatar{
    background: white;
    border-radius: 50%;
    padding: 5px;
}
.msg{
    text-align: left;
    font-size: 14px;
    font-weight: 800;
    display: inline-block;
    padding: 10px 15px 10px 5px;
    border-radius: 6px;
    /*width: calc(100% - 15px);*/
    width:auto;
    /*min-width: 100px;*/
    position: relative;
    color: var(--primary);
    background-color: white;
    border: 1px solid;
}
.link:before {
	content: '';
	position: absolute;
	width: 20px;
	height: 20px;
	right: -10px;
	top: 10px;
	color: var(--primary) !important;
    background-color: var(--primary) !important;
	-webkit-transform: rotate(45deg);
    border: 1px solid;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: var(--primary);
    border-bottom-color: var(--primary);
    cursor: pointer;
}
.link{
    background: var(--primary);
    color: #fff !important;
}

.link:hover {
    background: #fff !important;
    color:  var(--primary)!important;
    cursor: pointer;
}
.link:hover:before {
    background-color: #fff !important;
    color:  var(--primary)!important;
    border-right-color: var(--primary);
    border-top-color: var(--primary);
    border-left-color: white;
    border-bottom-color: white;
    
}
.tex{
    position: relative !important;
}
body {
    background-color: #eee
}

.chat-btn {
    width: 80px !important;
    height: 80px !important;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 50%);
    position: absolute;
    right: 14px;
    bottom: 30px;
    cursor: pointer;
    z-index: 100000;
    position: fixed;
}

.chat-btn .close {
    display: none
}

.chat-btn i {
    transition: all 0.9s ease
}


#check:checked~.chat-btn i {
    display: block;
    pointer-events: auto;
    transform: rotate(180deg)
}
#check:checked~ label {
    top: 0px !important;
}
#check:checked~.chat-btn .comment {
    display: none
}

.chat-btn i {
    font-size: 22px;
    color: #fff !important
}

.chat-btn {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background-color: var(--primary);
    color: #fff;
    font-size: 22px;
    border: none
}

.wrapper {
    position: fixed;
    right: 20px;
    bottom: 10px;
    width: 400px;
    background-color: #fff;
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 50%);
    border-radius: 5px;
    opacity: 0;
    display:none;
    transition: all 0.4s;
    z-index: 100;
    border: 1px solid var(--primary);
    height: 90%;
}
@media only screen and (max-width: 479px) {
    .wrapper {
        width: 95% !important;
        right:2%;
        left:2%;
    }
    .lod{
        bottom: 190px !important;
    }
    #check:checked~ label {
        top: 40px !important;
        width: 50px !important;
        height: 50px !important;
    }
    #check:checked~ label::after {
        top: 25px;
        left: 25px;
    }
    .chat-btn {
        width: 50px !important;
        height: 50px !important;
    }
    .chat-btn::after {
        left: 25px;
        top: 25px;
    }
}
#check:checked~.wrapper {
    display: block;
    opacity:1;
    transition: opacity 1s;
}

.header {
    padding: 13px;
    background-color: var(--primary);
    border-radius: 5px 5px 0px 0px;
    margin-bottom: 10px;
    color: #fff
}

.chat-form {
    background-color:white;
    padding: 15px;
    bottom: 0px !important;
    position: absolute;
    width: 100%;
}

.chat-form input,
textarea,
button {
    margin-bottom: 10px
}

.chat-form textarea {
    resize: none
}

.form-control:focus,
.btn:focus {
    box-shadow: none
}

.btn,
.btn:focus,
.btn:hover {
    background-color: var(--primary) !important;
    border: var(--primary) !important;
}

#check {
    display: none !important;
}
</style>
