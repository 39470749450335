import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import { api } from '../services.js'
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    pagamentoLoading: false,
    modalCheckout: false,
    cart: [],
    weather: {},
    dataIsRecived: false,
    logged: false,
    position: {
      lat: "",
      lon: "",
    },
    calendarOpts: {
      pt: {
        firstDayOfWeek: 0,
        dayNames: [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ],
        dayNamesShort: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        dayNamesMin: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
        monthNames: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro",
        ],
        monthNamesShort: [
          "Jan",
          "Fev",
          "Mar",
          "Abr",
          "Mai",
          "Jun",
          "Jul",
          "Ago",
          "Set",
          "Out",
          "Nov",
          "Dez",
        ],
        today: "Hoje",
        clear: "Limpar",
        dateFormat: "mm/dd/yy",
        weekHeader: "Wk",
      },
    },
    produto: {
      nome: "",
      id: "",
    },
    user: {
     
    }
  },
  mutations: {
    UPDATE_WEATHER(state, payload) {
      if(sessionStorage.weather){
        state.weather = JSON.parse(sessionStorage.weather)
        state.dataIsRecived = true;
      }
      else{
        axios
          .get(
            `https://api.weatherbit.io/v2.0/current?lang=pt&lat=${payload.latitude}&lon=${payload.longitude}&key=${process.env.VUE_APP_WEATHER}`
          )
          .then((response) => {
            state.weather = response.data.data[0];
            sessionStorage.weather = JSON.stringify(response.data.data[0])
            state.dataIsRecived = true;
          })
          .catch((error) => {
            console.log("There was an error:", error.response);
            state.dataIsRecived = false;
          });
      }
    },
    UPDATE_USER(state, payload) {
      state.user = Object.assign(state.user, payload);
    },
    SET_MODAL(state, payload) {
      state.modalCheckout = payload;
    },
    UPDATE_CART(state, payload) {
      state.cart.push(payload);
    },
    CLEAR_CART(state) {
      state.cart = [];
    },
    UPDATE_CART_SESSION(state, payload) {
      state.cart = payload;
    },
    REMOVE_CART(state, index) {
      state.cart.splice(index, 1);
  },
    UPDATE_PRODUTO(state, payload) {
      state.produto = Object.assign(state.produto, payload);
    },
    UPDATE_DADOS_CADASTRAIS(state, payload) {
      state.user = Object.assign(state.user, payload);
    },
    UPDATE_STATUS(state, payload) {
      state.logged = payload;
    },
  },
  actions: {
    addToCart(context, payload) {
      context.commit("UPDATE_CART", payload);
    },

    removeFromCart(context, index) {
      context.commit("REMOVE_CART", index);
    },
    getUserData(context){
      api.get('/api/user/me')
      .then(response => {
        context.commit("UPDATE_USER", response.data);
      })
      .catch(error => {
        console.error(error)
      })
    },
    validate(context) {
      this.$http.validate
        .then((response) => {
          if (response.data) {
            context.commit("UPDATE_STATUS", response.data);
          } else {
            context.commit("UPDATE_STATUS", false);
          }
        })
        .catch(() => {
          context.commit("UPDATE_STATUS", false);
        });
    },
    updateWeather(context) {
      var getPosition = function(options) {
        return new Promise(function(resolve, reject) {
          navigator.geolocation.getCurrentPosition(resolve, reject, options);
        });
      };

      getPosition()
        .then((position) => {
          context.commit("UPDATE_WEATHER", position.coords);
        })
        .then(() => {})
        .catch((err) => {
          console.error(err.message);
        });
    },
    LOGOUT(context) {
      context.commit("UPDATE_USER", null);
      sessionStorage.clear();
    },
  },
  modules: {},
  getters: {
    getUsuario: (state) => {
      return state.userDetails;
    },
    getCart: (state) => {
      return state.cart;
    },
  },
});
