<template>
  <div class="col-md-4 d-none d-md-block">
    <div
      class="input-group position-relative"
      v-if="$store.state.user.group != 'gestor'"
    >
    <div class="form_padrao pt-1" style=" width: 100%; ">
                    <div class="form-group flutuante">
                      <input type="text" name="search" id="search" style=" height: 35px; padding: 0px; " v-model="busca"
        @keydown.enter="Enter" 
        field="titulo"
        placeholder="O que você deseja aprender ?"/>
                      
                    


                    
                  </div>
                </div>
      
      <span class="material-icons abs">search</span>
    </div>
  </div>
</template>

<script>
import AutoComplete from "primevue/autocomplete";
import { filterByValue } from "../helpers.js";
export default {
  name: "ProdutosBuscar",
  data() {
    return {
      busca: "",
      buscaFiltrada: [],
      cursos: [],
    };
  },
  computed: {
    cursosLocal() {
      var filtering = [];
      var cursos = JSON.parse(localStorage.cursos);
      cursos.forEach((el) => {
        filtering.push(el.curso);
      });
      return filtering;
    },
  },
  components: {
    AutoComplete,
  },
  methods: {
    Enter(){
      window.location.href = location.origin+'?buscar='+this.busca;
    },
    irProduto(event) {
      this.$router.push({
        name: "curso",
        params: { id: event.value._id },
      });
    },
    //  getCursos() { 
    //      this.cursos = []
    //   this.$http
    //     .get('/api/cursos/all/')
    //     .then((response) => {
    //       response.data.entries.forEach(el => {
    //         this.cursos.push(el.curso)
    //       });
    //     });
    // },
    buscarProdutos(event) {
      this.buscaFiltrada = filterByValue(this.cursosLocal, event.query);
    },
  },
  created() {
    if (this.$route.query.buscar !== undefined && this.$route.query.buscar !== null) {
      this.busca = this.$route.query.buscar;
    }
  },
};
</script>

<style lang="scss">
.search-input {
  display: flex;
  justify-content: center;
  width: 100%;
  input {
    width: 100% !important;
  }
}
.abs {
  position: absolute;
  right: 10px;
  top: 8px;
}
.p-inputtext {
  box-shadow: 0 0 0 0.2rem transparent !important;
  border-color: transparent !important;
}
.p-inputtext:enabled:hover {
  box-shadow: 0 0 0 0.2rem transparent !important;
  border-color: transparent !important;
}
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem transparent !important;
  border-color: transparent !important;
}
</style>