<template>
    <div class="footer-version">
      <span>App version {{ appVersion }}</span>
    </div>
</template>

<script>
    import { version } from '../../package';
    export default {
        name: "TheFooter",
      data() {
          return {
            appVersion: version
          }
      }
    }
</script>

<style lang="scss" scoped>
  .footer-version {
    margin-top: 2vh;
    padding-top: 1vh;
    margin-left: auto;
    text-align: center;
    vertical-align: middle;
    color: gray;
    font-size: x-small;
    background-color: white;
    line-height: 1.8rem;
  }
</style>